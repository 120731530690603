<template>
  <VueSlickCarousel
    v-show="pageIsLoaded"
    :arrows="true"
    :dots="true"
    :swipe-to-slide="true"
    :swipe="true"
    :autoplay="!!autoplay"
    :autoplay-speed="autoplay.delay"
    @beforeChange="handleChangeSlide"
  >
    <template #prevArrow>
      <div class="custom-arrow">
        <svg-icon
          name="arrow-left"
          width="32"
          height="32"
        />
      </div>
    </template>
    <div
      v-for="(banner, index) in banners"
      :key="index"
    >
      <BannerComponent :info="banner" loading="default" :ratio="ratio" :sizes="sizes" />
      <!--      banner {{index}}-->
    </div>
    <template #nextArrow>
      <div class="custom-arrow">
        <svg-icon
          name="arrow-right"
          width="32"
          height="32"
        />
      </div>
    </template>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import BannerComponent from '~/components/catalog/banner/index';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'BannerSlickSlider',
  components: {
    BannerComponent,
    VueSlickCarousel,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    banners: {
      type: Array,
      required: true,
    },
    autoplay: {
      type: [Object, Boolean],
      default: false,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    sizes: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      pageIsLoaded: false,
    }
  },
  computed: {
    swiperSlider() {
      return this.sliderBanner
    },
  },
  mounted() {
    this.pageIsLoaded = true;
  },
  methods: {
    handleChangeSlide(_, indNext) {
      this.$emit('changeSlide', indNext)
    },
  },
}
</script>
<style>
.slick-track {
  display: flex;
}
.slick-slider {
  position: relative;
}
.slick-slide {
  visibility: hidden;
}
.slick-active {
  visibility: visible;
}
.slick-arrow {
  z-index: 1;
}
.custom-arrow {
  &.slick-prev, &.slick-next {
    &::before {
      content: '';
    }
  }
}
.slick-prev {
  left: 30px !important;
}
.slick-next {
  right: 40px !important;
}
.slick-dots {
  margin-bottom: 38px;
  li {
    margin: 0;
    button::before {
      color: #696969;
      opacity: 1;
      font-size: 8px;
    }
    &.slick-active {
      button::before {
        color: #fff;
      }
    }
  }
}

</style>
